export let domain = "https://jws.nivid.app";
export let subdomain = "kavre";
const origin = window.location.origin;
const hostname = window.location.hostname;
const parts = hostname.split(".");
export const subdomainIndex = parts[0];

const liveUrlPattern1 = /^(https?:\/\/)?[a-zA-Z0-9-]+\.web\.nivid\.app$/;
const liveUrlPattern2 = /^https:\/\/[a-zA-Z0-9-]+\.edu\.np$/;

function testUrl() {
  return liveUrlPattern1.test(origin) || liveUrlPattern2.test(origin);
}
const matchOrigin = testUrl(origin);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  domain = "https://" + subdomainIndex + ".nivid.app";
  subdomain = subdomainIndex;
} else {
  subdomain = subdomainIndex;

  if (matchOrigin) {
    domain = `https://${subdomainIndex}.nivid.app`;
  } else {
    // domain = `https://${subdomainIndex}.uat.nivid.app`;
    domain = `https://${subdomainIndex}.nivid.app`;
  }

  // domain = origin.replace("react.", "");
}
