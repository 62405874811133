import React, { useState, useEffect } from "react";
import "./OurBenifitsB.scss";
import HeadingB from "../HeadingB/HeadingB";
import { IoPeopleOutline } from "react-icons/io5";
import { GoBook } from "react-icons/go";
import { TbBuildingCommunity } from "react-icons/tb";
import { TbDeviceGamepad2 } from "react-icons/tb";
import { RiUserShared2Line } from "react-icons/ri";

import useApiState from "../../store/store";
import { filterKeyword } from "../../utils/filterKeywords";
// import * as Tabler from "react-tabler-icons";
import * as Tabler from "tabler-icons-react";

const OurBenifits = () => {
  const { allData } = useApiState();

  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("facility_")
  );

  const benefitsValue = ["benefits"];

  const matchedData = filterKeyword(
    allData?.singlePageContent?.data,
    benefitsValue
  );
  const iconList = [
    {
      icon: <TbBuildingCommunity color="white" />,
      keyword: "building-community",
    },
    { icon: <TbDeviceGamepad2 color="white" />, keyword: "device-gamepad-2" },
    { icon: <RiUserShared2Line color="white" />, keyword: "user-share" },
  ];
  return (
    <div className="BenifitsB-section">
      <div className="container">
        <div className="benifit_top_section">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="top_left_section">
                <HeadingB
                  title={matchedData?.[0]?.title || "Our Benefits"}
                  subTitle={matchedData?.[0]?.subtitle || "subtitle"}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <p className="descB">
                {/* <Activity /> */}
                {/* <Tabler.Activity /> */}
                {/* Find your voice, your movement, yourself. Opportunities for all
            levels in acting and dance, stagecraft, puppetry, playwriting,
            speechwriting and more. Join one of many clubs to explore even
            further. */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: matchedData?.[0]?.content,
                  }}
                  data-aos="fade-left"></div>
              </p>
            </div>
          </div>
        </div>

        <div className="benifit_bottom_section">
          <div className="row">
            {matchedItems?.map((item, index) => {
              const IconName = `${item.icon
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join("")}`;

              const Icon = Tabler[IconName];
              const defaultIcon = iconList.filter(
                (icon) => icon.keyword === item.icon
              );
              return (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 col-xl-3 col-sm-12 card"
                  data-aos="fade-right">
                  <div className="card_content_section">
                    <div className="card_img_section">
                      {Icon ? <Icon color="white" /> : defaultIcon[0]?.icon}
                    </div>
                    <div className="heading">{item?.title}</div>
                    <div className="desc" data-aos="fade-left">
                      {/* Using `dangerouslySetInnerHTML` to render HTML content */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBenifits;
