import React, { useEffect, useState } from "react";

import "./schoolFeatures.scss";

import { useNavigate } from "react-router-dom";
import useApiState from "../../store/store";
function SchoolFeatures() {
  const [featuredStories, setFeaturedStories] = useState();
  const { allData } = useApiState();
  useEffect(() => {
    setFeaturedStories(allData?.schoolWalls?.data);
  }, []);
  const news = allData?.upcomingEvents?.data;

  let navigate = useNavigate();
  return (
    <>
      <div className="container section-gap">
        <div className="school-features-section">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="features-section">
                <h1 className="sub_headingB features-title">
                  School Featured <span>Stories</span>
                </h1>
                <hr />
                <div className="features-container">
                  {featuredStories?.slice(0, 3).map((item, index) => (
                    <div key={index} className="features-card">
                      <div className="image-section">
                        {item.post_image ? (
                          <img src={item.post_image} alt="" />
                        ) : (
                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWA8eRoFDTR6FnHVjEk_0AjDdw7QgvohOnxQ&s" />
                        )}
                      </div>
                      <div className="desc-section">
                        <p className="date">{item.postedDate}</p>
                        <h1 className="descB">{item.title}</h1>
                        <p className="descC">
                          {item.description.slice(0, 150)}...
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="news-and-event">
                <div className="container">
                  <h1 className="event-title">Latest Events</h1>
                  <div className="event-container">
                    {news?.map((item, index) => (
                      <div key={index} className="event-card">
                        <img src={item.image} alt="" />
                        <div className="event-sub-card">
                          <p className="dateB">
                            {item.month}, {item.day}
                          </p>
                          <h1 className="titleC">{item.description}</h1>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      navigate("/events");
                    }}
                    className=" event-btn">
                    View All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SchoolFeatures;
