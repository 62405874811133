import React, { useEffect } from "react";
import AllTeam from "./AllTeam";
import { MetaConfigure } from "../helmet";

const AllTeamWrapper = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginTop: "50px", padding: "30px" }}>
      <MetaConfigure title="Our Team" />

      <div className="container">
        <h2>Our Team</h2>
        <AllTeam />
      </div>
    </div>
  );
};

export default AllTeamWrapper;
