import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./SliderBanner.scss";
import { FaLongArrowAltRight } from "react-icons/fa";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useApiState from "../../../store/store";

export const SliderBanner = () => {
  const [webText, setWebText] = useState();
  const { allData } = useApiState();
  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("Cover_")
  );

  const NextArrow = ({ onClick, className }) => {
    return (
      <button className="slider-banner-next-arrow" onClick={onClick}>
        <MdOutlineKeyboardArrowRight color="white" size={30} />
      </button>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="slider-banner-prev-arrow" onClick={onClick}>
        <MdOutlineKeyboardArrowLeft color="white" size={30} />
      </button>
    );
  };

  let navigate = useNavigate();
  const settings = {
    slidesToShow: 1,
    swipeToSlide: true,
    infinite: true,
    focusOnSelect: true,
    arrows: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-banner">
      <Slider {...settings}>
        {matchedItems.map((item) => {
          return (
            <div className="slider_banner_container">
              <div className="img_box">
                <img src={item?.title_image_link} alt="img" />
              </div>
              <div className="content_box">
                <h2 className="title">{item?.subtitle}</h2>
                <p>{item?.title}</p>

                <button
                  className="btn transparent-btn"
                  onClick={() => {
                    navigate("/admission_form");
                  }}>
                  Enroll now
                  <span>
                    <FaLongArrowAltRight />
                  </span>
                </button>
              </div>
              <div className="overlay"></div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
