import React from "react";
import "./BenefitsContainerView.scss";
import Heading from "../Heading/Heading";
import useApiState from "../../store/store";
import * as Tabler from "tabler-icons-react";
import { filterKeyword } from "../../utils/filterKeywords";

import { useNavigate } from "react-router-dom";

export const BenefitsContainerView = () => {
  let navigate = useNavigate();
  const { allData } = useApiState();

  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("facility_")
  );

  const benefitsValue = ["benefits"];
  const benefits = allData?.webPageText?.data?.[0]?.school_benefit;

  const matchedData = filterKeyword(
    allData?.singlePageContent?.data,
    benefitsValue
  );

  return (
    <div className="benefits-container-view">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <Heading
              title={matchedData?.[0]?.title}
              subtitle={matchedData?.[0]?.subtitle}
            />

            <p className="description">
              <div
                dangerouslySetInnerHTML={{
                  __html: matchedData?.[0]?.content,
                }}
                data-aos="fade-left"></div>
            </p>

            <button
              className="viewAll_btn "
              style={{
                background: "transparent",
              }}
              onClick={() => {
                navigate("/admission_form");
              }}>
              Enroll Now
            </button>
          </div>
          <div className="col-md-7">
            <div className="row">
              {matchedItems?.map((item, index) => {
                const IconName = `${item.icon
                  .split("-")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join("")}`;

                const Icon = Tabler[IconName];

                return (
                  <div className="col-md-6" key={index}>
                    <div className="card-container card-wrap">
                      <p> {Icon && <Icon />}</p>

                      <h5 className="card-title">{item?.title}</h5>
                      <p className="description">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}></div>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
